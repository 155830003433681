<template>
    <div class="dashboard-main">
        <div class="dashboard-main__container">
            <div class="info"><strong>Всего судов: </strong> {{ info.shipsNumber }}</div>
            <div class="info"><strong>Всего круизов: </strong> {{ info.cruisesNumber }}</div>
            <div class="info"><strong>Всего заказов: </strong> {{ info.ordersNumber }}</div>
            <div class="info"><strong>Всего страниц: </strong> {{ info.pagesNumber }}</div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    setup() {},
    data() {
        return {
            loading: false,
            info: {
                shipsNumber: 0,
                cruisesNumber: 0,
                ordersNumber: 0,
                pagesNumber: 0,
            },
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Dashboard');
        this.loading = true;
        await this.fetchStat();
        this.loading = false;
    },
    methods: {
        async fetchStat() {
            try {
                const { data } = await this.axios.get('/dashboard/info');
                console.log('data', data);
                if (data.success === true) {
                    this.info = { ...data.data };
                }
            } catch (ex) {
                console.log('cant fetch data: ' + ex);
            }
        },
    },
});
</script>
<style scoped lang="scss">
.dashboard-main {
    &__container {
        @apply flex flex-wrap gap-6 mt-5;
    }

    &__column {
        flex: 1 1 20rem;
    }

    &__column-title {
        @apply flex items-center font-medium text-base px-5 py-3 text-gray-600 overflow-y-auto;
        max-height: 5rem;
        height: 100%;
    }

    &__empty-message {
        @apply px-5 py-3 bg-white rounded-lg drop-shadow-sm font-bold;
        text-align: center;
    }
}
</style>
